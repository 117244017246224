.printableDiv {
    background-color: #ffffff;
    width: 100%;
    height: calc(100vh);
    border: none !important;
    border-radius: 5px;
    margin: auto;
    text-align: center;
}

table {
    background-color: #ffffff;
    border: none !important;
    display: inline-table;
}

.noneBorder {
    border-top: 2 px solid #ffffff !important;
    border-bottom: 2 px solid #ffffff !important;
}

 td:nth-child(odd) {
     background-color: #ffffff;
 }