.MDTable {
    width: 1800px !important;
    font-size: .7em !important;
    font-family: Arial, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif;
    background-color: #ffffff;
}

.MDHeaderSticky {
    position: 'sticky';
    top: '0'
}

/* .MDTable th, */
.MDTable td {
    padding: 5px 0px 0px 0px;
    border: none;
    /*    padding-top: 5px; */
    background-color: #ebecfa;
}

.MDTable tr {
    background-color: #dddcdc;
}

.MDTable thead tr th {
    /* text-shadow: 1px 1px 1px #ccc; */
    font-size: 1em;
    background-color: #255dde;
    color: #FFFFFF;
    height: 25px;
    text-align: center !important;
}

.MDTable tbody tr td {
    border-top: solid 1px #c8cacd;
    /*  border-bottom: solid 1px #092a84; */
    font-size: 1em !important;
    background-color: #FFFFFF;
    /*  height: 25px; */
}

.MDTable tbody tr:nth-of-type(even) {
    background-color: #c2bfbf;
}

.MDTable tbody tr:nth-of-type(odd) {
    background-color: #c0bdbd;
}

.MDcontainer {
    width: 100%;
    height: 500px;
    overflow: auto;
}

.totalLabel {
    color: #f4a005;
    font-size: larger;
    font-weight: bold;
    background-color: #ffffff;
    text-shadow: 1px 1px 1px rgb(246, 218, 6);
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif
}

.masterSelected {
    color: #000000;
    border-top: solid 4px #8b73e2;
    border-right: solid 1px #8b73e2;
    border-left: solid 1px #8b73e2;
    text-align: center !important;
}

.normalSelected {
    color: #000000;
    border-top: none;
    border-bottom: none;
    text-align: center !important;
}

.masterSelected2 {
    color: #000000;
    border-bottom: solid 3px #8b73e2;
    border-right: solid 1px #8b73e2;
    border-left: solid 1px #8b73e2;
    text-align: center !important;
}

.normalSelected2 {
    color: #000000;
    border-top: none;
    border-bottom: none;
    text-align: center !important;
}

.zui-table {
    border: solid 1px #DDEEEE;
    border-collapse: collapse;
    border-spacing: 5px;
    font: normal 11px Arial, sans-serif;
}

.zui-table thead tr th {
    background-color: #DDEFEF;
    border: solid 1px #DDEEEE;
    color: #336B6B;
    padding: 5px 5px 5px 15px;
    text-align: center;
    text-shadow: 1px 1px 1px #fff;
}

.zui-table tbody td {
    border: solid 1px #DDEEEE;
    color: #333;
    padding: 0px;
    text-shadow: 1px 1px 1px #fff;
    padding: 0px 0px 0px 15px;
    border-bottom: none;
    border-top: none;
}

.zui-table-rounded {
    border: none;
}

.zui-table-rounded thead th {
    background-color: #70c1cf;
    border: none;
    text-shadow: 1px 1px 1px #ccc;
    color: #333;
}

.zui-table-rounded thead th:first-child {
    border-radius: 10px 0 0 0;
}

.zui-table-rounded thead th:last-child {
    border-radius: 0 10px 0 0;
}

.zui-table-rounded tbody tr td {
    border: none;
    border-top: solid 1px #09840f;
    background-color: #ffffff;
    border-bottom: none;
}

.zui-table-rounded tbody tr:last-child td:first-child {
    border-radius: 0 0 0 10px;
}

.zui-table-rounded tbody tr:last-child td:last-child {
    border-radius: 0 0 10px 0;
}

.parent {
    position: relative;
    margin-top: 0px;
    z-index: 1;
}

.child {
    position: absolute;
    margin-top: 30px;
    width: 350px;
    height: 150px;
    background: rgb(245, 248, 248);
    border-radius: 5px 5px 5px 5px;
    padding: 2px;
    border-style: outset;
    overflow-y: scroll;
}

.cleanLi {
    list-style-type: none;
    padding-left: 10px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 12px;
    cursor: pointer;
}

.divAutocomplete {
    z-index: 1000;
    position: relative;
    height: 100px;
    overflow: auto;
    /*  display: block;
   
    position: relative; */
    display: inline-block;
}
.modal-90w {
    width: 90%;
    max-width: none !important;
    max-height: none !important;
}

.childTable table thead tr:first-child{
    border-radius:10px 0 0 10px;
  }
  
.childTable table thead tr:last-child{
    border-radius:0 10px 10px 0;
}
