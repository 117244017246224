.tripHeader {
    font-size: large;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.tripField {
    font-size:15px;
    font-weight: normal;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif
}

.rowSplit {
    padding-top: '5px'
}

.divTipoCatalogo {
    height: 200px;
    overflow-y: scroll;
}

.DetailHeader {
    width: 50px;
    background-color: #CAF9F7;
    color:blue;
}

.DetailData {
    font-weight: normal;
}
